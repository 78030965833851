import { FC, useCallback, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import {
	EmailSharp as EmailSharpIcon,
	Lock as LockIcon,
	ArrowRightAlt as ArrowRightAltIcon,
	VisibilityOff as VisibilityOffIcon,
	Visibility as VisibilityIcon
} from '@mui/icons-material';

import { generateValidation } from 'shared/constants/validation';
import { API_CONFIG } from 'shared/constants/api';
import { LanguageCodes, ROUTES } from 'shared/constants/constant';
import MMSCANTextField from 'shared/components/MMSCANTextField/MMSCANTextField';
import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';
import HttpService from 'shared/services/Http.service';
import AuthService from 'shared/services/Auth.service';
import { IAction } from 'shared/interface/state';
import { createAction } from 'shared/util/Utility';
import localizationConstants from 'shared/util/translation/constant';
import { CommonLinkSxProps, StyledAuthTitle } from 'assets/styles/Common.style';
import * as actionTypes from 'store/action-types';

import { ILoginFormInput } from './LoginScreen.type';

const LoginScreen: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, IAction> = useDispatch();
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<ILoginFormInput>({
		defaultValues: { email: '', password: '' }
	});

	const submitLoginForm = useCallback(
		async (params: ILoginFormInput) => {
			try {
				setLoading(true);
				dispatch(createAction(actionTypes.AUTH_INIT));
				const result = await HttpService.post(API_CONFIG.login, params);
				AuthService.setAuthData(result.data);
				if (!localStorage.getItem('lang')) {
					localStorage.setItem('lang', LanguageCodes[result.data.language] as string);
				}
				dispatch(createAction(actionTypes.AUTH_SUCCESS, result.data));
				navigate(ROUTES.DASHBOARD);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				dispatch(createAction(actionTypes.AUTH_FAIL));
			}
		},
		[navigate, dispatch]
	);
	return (
		<>
			<StyledAuthTitle data-testid='loginTitle'>Login</StyledAuthTitle>
			<form className='width--full' onSubmit={handleSubmit(submitLoginForm)}>
				<Controller
					control={control}
					rules={generateValidation(t(localizationConstants.email)).email}
					name='email'
					render={({ field: { ref, onChange, ...rest } }) => (
						<MMSCANTextField
							label='Email'
							data-testid='email'
							placeholder='Enter Your Email'
							boxProps={{ marginBottom: '20px' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<EmailSharpIcon fontSize='small' />
									</InputAdornment>
								)
							}}
							error={!!errors.email}
							helperText={errors.email?.message}
							onChange={({ target: { value } }) => onChange(value.trim())}
							inputRef={ref}
							{...rest}
						/>
					)}
				/>
				<Controller
					control={control}
					rules={generateValidation(t(localizationConstants.password)).required}
					name='password'
					render={({ field: { ref, onChange, ...rest } }) => (
						<MMSCANTextField
							label='Password'
							type={showPassword ? 'text' : 'password'}
							autoComplete='true'
							data-testid='password'
							placeholder='Enter Your Password'
							boxProps={{ marginBottom: '36px' }}
							onChange={({ target: { value } }) => onChange(value.trim())}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<LockIcon fontSize='small' />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={() => setShowPassword(!showPassword)}
											edge='end'
											data-testid='passwordToggle'
										>
											{showPassword ? (
												<VisibilityOffIcon fontSize='small' />
											) : (
												<VisibilityIcon fontSize='small' />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
							error={!!errors.password}
							helperText={errors.password?.message}
							inputRef={ref}
							{...rest}
						/>
					)}
				/>
				<MMSCANButton data-testid='loginButton' fullWidth text='Login' loading={isLoading} type='submit' />
			</form>
			<Typography
				data-testid='forgotPasswordLink'
				sx={CommonLinkSxProps}
				onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
			>
				Forgot Password? <ArrowRightAltIcon fontSize='small' />
			</Typography>
		</>
	);
};

export default LoginScreen;
