export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const NOTIFICATION_API_CALL = 'NOTIFICATION_API_CALL';
export const SELECTED_COMPANY_DATA = 'SELECTED_COMPANY_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';

export const USER_DATA = 'USER_DATA';
export const DEALER_DATA = 'DEALER_DATA';
export const VISIT_DATA = 'VISIT_DATA';
export const FS_FILTER = 'FS_FILTER';
export const CLOSED_DATA = 'CLOSED_DATA';
export const AGENDA_ACTION = 'AGENDA_ACTION';
export const ACTION_SCREEN = 'ACTION_SCREEN';
export const BUSINESS_PLAN = 'BUSINESS_PLAN';

export const VISIT_SUMMARY_REPORTS_DATA = 'VISIT_SUMMARY_REPORTS_DATA';
export const ACTION_ITEM_REPORT_YEAR = 'ACTION_ITEM_REPORT_YEAR';
