import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { AxiosResponse, AxiosError } from 'axios';

import { axiosInstance } from 'shared/services/Http.service';
import { createAction } from 'shared/util/Utility';
import Notification, { notify } from 'shared/components/notification/notification';
import { IResponseObject } from 'shared/interface';
import * as actionTypes from 'store/action-types';
import { DEFAULT_PARAMS, LanguageCodes } from 'shared/constants/constant';
import { fsFilterInitialState } from 'features/financialStatement/store/financialStatement.reducer';
import { visitSummaryReportInitialState } from 'features/visitManagement/store/visitSummaryReport.reducer';

const ErrorHandler: FC = () => {
	const dispatch = useDispatch();

	const pathname = window.location.pathname;
	const isVerifyAccount = pathname.includes('/verify-account');
	const noToastPathList = useMemo(
		() => !pathname.includes('/reset-password') && !isVerifyAccount,
		[isVerifyAccount, pathname]
	);

	const logout = useCallback(() => {
		dispatch(createAction(actionTypes.AUTH_FAIL));
		// Define default parameters once
		const defaultParams = DEFAULT_PARAMS;
		const emptyFilter = {};
		const dataPayload = { pagination: defaultParams, filter: emptyFilter };
		const actionsWithDefaultParams = [
			actionTypes.USER_DATA,
			actionTypes.DEALER_DATA,
			actionTypes.VISIT_DATA,
			actionTypes.AGENDA_ACTION,
			actionTypes.ACTION_SCREEN,
			actionTypes.BUSINESS_PLAN
		];

		// Dispatch actions with default parameters
		actionsWithDefaultParams.forEach((action) => {
			dispatch(createAction(action, dataPayload));
		});
		dispatch(createAction(actionTypes.FS_FILTER, { ...fsFilterInitialState }));
		dispatch(createAction(actionTypes.CLOSED_DATA, { year: moment().year() }));
		dispatch(createAction(actionTypes.VISIT_SUMMARY_REPORTS_DATA, visitSummaryReportInitialState));
		localStorage.setItem('lang', LanguageCodes['English'] as string);
	}, [dispatch]);

	const handleSuccessResponse = useCallback(
		(res: AxiosResponse<IResponseObject<any>>) => {
			const { data, config } = res;
			if (data?.message && config?.method !== 'get' && !isVerifyAccount) {
				notify(data.message, 'success');
			}
			return res;
		},
		[isVerifyAccount]
	);

	const handleErrorResponse = useCallback(
		(error: AxiosError<IResponseObject<any>>) => {
			const { response } = error;
			if (response?.data && response.status) {
				const { status, data } = response;
				if ((status === 401 || status === 403) && data) {
					logout();
				}
				if (noToastPathList) {
					notify(data.message, 'error');
				}
				throw error;
			}
		},
		[logout, noToastPathList]
	);

	useEffect(() => {
		const resInterceptor = axiosInstance.interceptors.response.use(handleSuccessResponse, handleErrorResponse);
		return () => axiosInstance.interceptors.response.eject(resInterceptor);
	}, [handleErrorResponse, handleSuccessResponse]);
	return <Notification />;
};

export default ErrorHandler;
