import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';
import {
	Lock as LockIcon,
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';

import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';
import MMSCANLoader from 'shared/components/MMSCANLoader/MMSCANLoader';
import MMSCANTextField from 'shared/components/MMSCANTextField/MMSCANTextField';
import HttpService from 'shared/services/Http.service';
import { generateValidation } from 'shared/constants/validation';
import { API_CONFIG } from 'shared/constants/api';
import { ROUTES } from 'shared/constants/constant';
import localizationConstants from 'shared/util/translation/constant';

import { StyledAuthTitle } from 'assets/styles/Common.style';
import ErrorAlert from './components/ErrorAlert';

interface IResetPasswordFormInput {
	password: string;
}

const ResetPasswordScreen: FC = () => {
	const { token, userId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [isResetPassword, setIsResetPassword] = useState({
		isLoading: false,
		showPassword: false,
		isVerifySuccess: false,
		isInvalidToken: false,
		isError: false
	});
	const [errorMessage, setErrorMessage] = useState('');

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<IResetPasswordFormInput>({
		defaultValues: { password: '' }
	});

	const verifyEmail = useCallback(async () => {
		if (!token || !userId) return navigate(ROUTES.LOGIN);
		setIsResetPassword((prev) => ({ ...prev, isLoading: true }));

		try {
			await HttpService.post(API_CONFIG.verifyResetPasswordEmail, { token, user_id: userId });
			setIsResetPassword((prev) => ({ ...prev, isLoading: false, isVerifySuccess: true }));
		} catch (error: any) {
			const isInvalidToken = error?.response?.status === 401;
			setIsResetPassword((prev) => ({
				...prev,
				isLoading: false,
				isInvalidToken,
				isError: !isInvalidToken,
				isVerifySuccess: true
			}));
			setErrorMessage(isInvalidToken ? '' : error.response.data.message);
		}
	}, [token, userId, navigate]);

	const submitResetPasswordForm = useCallback(
		async (values: IResetPasswordFormInput) => {
			setIsResetPassword((prev) => ({ ...prev, isLoading: true }));
			try {
				const params = { password: values.password, user_id: userId };
				await HttpService.post(API_CONFIG.resetPassword, params);
				navigate(ROUTES.LOGIN);
			} catch {
				setIsResetPassword((prev) => ({ ...prev, isLoading: false }));
			}
		},
		[navigate, userId]
	);

	useEffect(() => {
		verifyEmail();
	}, [verifyEmail]);

	const handleTogglePassword = () => setIsResetPassword((prev) => ({ ...prev, showPassword: !prev.showPassword }));

	const { isLoading, showPassword, isVerifySuccess, isInvalidToken, isError } = isResetPassword;

	return (
		<>
			<StyledAuthTitle data-testid='resetPasswordTitle'>Reset Password</StyledAuthTitle>

			{isError && (
				<ErrorAlert
					message={errorMessage}
					buttonText='Forgot Password'
					buttonRoute={ROUTES.FORGOT_PASSWORD}
					linkText='Go To Login'
					linkRoute={ROUTES.LOGIN}
					navigate={navigate}
				/>
			)}

			{!isLoading && isInvalidToken && (
				<ErrorAlert
					message='Link is expired. Please try again.'
					buttonText='Forgot Password'
					buttonRoute={ROUTES.FORGOT_PASSWORD}
					linkText='Go To Login'
					linkRoute={ROUTES.LOGIN}
					navigate={navigate}
				/>
			)}

			{isLoading && <MMSCANLoader />}

			{isVerifySuccess && !isInvalidToken && !isError && !isLoading && (
				<form className='width--full' onSubmit={handleSubmit(submitResetPasswordForm)}>
					<Controller
						control={control}
						rules={generateValidation(t(localizationConstants.password)).password}
						name='password'
						render={({ field: { ref, onChange, ...rest } }) => (
							<MMSCANTextField
								label='New Password'
								type={showPassword ? 'text' : 'password'}
								data-testid='password'
								placeholder='Enter Your New Password'
								boxProps={{ marginBottom: '20px' }}
								onChange={({ target: { value } }) => onChange(value.trim())}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<LockIcon fontSize='small' />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												data-testid='passwordToggle'
												onClick={handleTogglePassword}
												edge='end'
											>
												{showPassword ? (
													<VisibilityOffIcon fontSize='small' />
												) : (
													<VisibilityIcon fontSize='small' />
												)}
											</IconButton>
										</InputAdornment>
									)
								}}
								error={!!errors.password}
								helperText={errors.password?.message}
								inputRef={ref}
								{...rest}
							/>
						)}
					/>
					<MMSCANButton
						data-testid='submitButton'
						fullWidth
						text='Submit'
						loading={isLoading}
						type='submit'
					/>
				</form>
			)}
		</>
	);
};

export default ResetPasswordScreen;
