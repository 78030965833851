import { lazy } from 'react';

const AccessDenied = lazy(() => import('shared/components/MMSCANAccessDenied/MMSCANAccessDenied'));
const ActionItems = lazy(() => import('features/visitManagement/components/ActionItems'));
const AgendaActionContainer = lazy(() => import('features/visitManagement/AgendaActionContainer'));
const BusinessPlan = lazy(() => import('features/businessPlan/BusinessPlan'));
const BusinessPlanDetails = lazy(() => import('features/businessPlan/component/BusinessPlanDetails'));
const BusinessPlanHistory = lazy(() => import('features/businessPlan/component/BusinessPlanHistory'));
const ChangePassword = lazy(() => import('hoc/layout/component/ChangePassword'));
const ChangeRequest = lazy(() => import('features/financialDocs/components/ChangeRequest'));
const ClosedData = lazy(() => import('features/financialDocs/components/ClosedData'));
const Dashboard = lazy(() => import('features/dashboard/Dashboard'));
const DealerFormScreen = lazy(() => import('features/dealerSessionManagement/components/DealerFormScreen'));
const DealerSessionManagement = lazy(() => import('features/dealerSessionManagement/DealerSessionManagement'));
const FinancialStatement = lazy(() => import('features/financialStatement/FinancialStatement'));
const MyProfile = lazy(() => import('hoc/layout/component/MyProfile'));
const PowerBIReport = lazy(() => import('features/powerBI/PowerBIReport'));
const PowerBIReport1 = lazy(() => import('features/powerBI/PowerBIReport1'));
const PowerBIReport2 = lazy(() => import('features/powerBI/PowerBIReport2'));
const Reports = lazy(() => import('features/reports/components/Reports'));
const ReportsContainer = lazy(() => import('features/reports/ReportsContainer'));
const SubmissionTable = lazy(() => import('features/financialDocs/components/SubmissionTable'));
const User = lazy(() => import('features/user/UserScreen'));
const UserFormScreen = lazy(() => import('features/user/components/UserFormScreen'));
const VisitFormScreen = lazy(() => import('features/visitManagement/components/VisitFormScreen'));
const VisitManagement = lazy(() => import('features/visitManagement/VisitManagement'));
const ViewActionItems = lazy(() => import('features/visitManagement/components/ViewActionItems'));
const VisitSummaryReport = lazy(() => import('features/visitManagement/components/VisitSummaryReport'));
const ActionItemReport = lazy(() => import('features/visitManagement/components/ActionItemReport'));
const EmbedReportContainer = lazy(() => import('features/reports/EmbedReportContainer'));
const EmbedReport = lazy(() => import('features/reports/components/EmbedReport'));

export {
	AccessDenied,
	ActionItems,
	AgendaActionContainer,
	BusinessPlan,
	BusinessPlanDetails,
	BusinessPlanHistory,
	ChangePassword,
	ChangeRequest,
	ClosedData,
	Dashboard,
	DealerFormScreen,
	DealerSessionManagement,
	FinancialStatement,
	MyProfile,
	PowerBIReport,
	PowerBIReport1,
	PowerBIReport2,
	Reports,
	ReportsContainer,
	SubmissionTable,
	User,
	UserFormScreen,
	VisitFormScreen,
	VisitManagement,
	ViewActionItems,
	VisitSummaryReport,
	ActionItemReport,
	EmbedReportContainer,
	EmbedReport
};
