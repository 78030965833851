import moment from 'moment';
import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IClosedDataFilter } from 'shared/interface';

export const actionItemReportInitialState: IClosedDataFilter = {
	year: moment().year()
};

const actionItemReportReducer = (state = actionItemReportInitialState, action: IAction): IClosedDataFilter => {
	switch (action.type) {
		case actionTypes.ACTION_ITEM_REPORT_YEAR:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default actionItemReportReducer;
