import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './translation/fr';
import en from './translation/en';

const resources = {
	fr: {
		translation: fr
	},
	en: {
		translation: en
	}
};
i18n.use(initReactI18next as any) // passes i18n down to react-i18next
	.init(
		{
			resources,
			lng: localStorage.getItem('lang') || 'de',
			keySeparator: false, // we do not use keys in form messages.welcome
			interpolation: {
				escapeValue: false
			}
		},
		(error: any) => {
			console.error(error);
		}
	);
export default i18n;

const refreshLanguage = (language: string) => {
	i18n.changeLanguage(language);
	localStorage.setItem('lang', language);
};
export { refreshLanguage };
