import * as actionTypes from 'store/action-types';
import { IAction, IVisitSummaryReportState } from 'shared/interface/state';
import { convertToShortMonth } from 'shared/util/Utility';

export const visitSummaryReportInitialState: IVisitSummaryReportState = {
	start_month: convertToShortMonth(new Date() as any, 'MM'),
	start_year: convertToShortMonth(new Date() as any, 'year'),
	end_month: convertToShortMonth(new Date() as any, 'MM'),
	end_year: convertToShortMonth(new Date() as any, 'year'),
	dealer_code: ''
};

const VisitSummaryReportReducer = (
	state: IVisitSummaryReportState = visitSummaryReportInitialState,
	action: IAction
): IVisitSummaryReportState => {
	switch (action.type) {
		case actionTypes.VISIT_SUMMARY_REPORTS_DATA:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default VisitSummaryReportReducer;
