import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import ErrorHandler from 'hoc/withErrorHandler/ErrorHandler';
import i18n from 'shared/util/localization';
import ErrorBoundary from 'shared/components/errorBoundary/ErrorBoundary';
import App from 'App';
import store from 'store';
import { theme } from 'assets/styles/MMSCANTheme';
import 'assets/styles/app.scss';
import 'react-datasheet-grid/dist/style.css';

const msalConfig = {
	auth: {
		clientId: 'de18de1d-e634-4565-b36c-be9b92bbc08d', // Replace with your Azure AD Application (client) ID
		authority: 'https://login.microsoftonline.com/4e00599b-2387-4206-b891-8fdcabad28cc', // Replace with your Azure AD Tenant ID
		redirectUri: window.location.origin // Replace with your redirect URI
	}
};

// Create MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const Root: React.FC = () => {
	return (
		<I18nextProvider i18n={i18n}>
			<ErrorBoundary>
				<Provider store={store}>
					<MsalProvider instance={msalInstance}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<ThemeProvider theme={theme}>
								<ErrorHandler />
								<BrowserRouter>
									<App />
								</BrowserRouter>
							</ThemeProvider>
						</LocalizationProvider>
					</MsalProvider>
				</Provider>
			</ErrorBoundary>
		</I18nextProvider>
	);
};

export default Root;
